import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { PageId } from "../components/Navigation";
import { getHeight, getIsVertical } from "../utils/getIsVertical";
import { Rure } from "../components/Rure";
import { COLORS } from "../utils/consts";

import "../styles/index.css";

function getBGImageWidth(isVert: boolean, currentLng?: string) {
	if (isVert) {
		return;
	}

	switch (currentLng) {
		case "en":
			return "32rem";
		case "ru":
			return "29rem";
		default:
			return;
	}
}

export default function IndexPage() {
	const { t, i18n } = useTranslation();
	const [containerHeight, setContainerHeight] = React.useState("100vh");
	const isVertical = getIsVertical();

	const onResize = React.useCallback(() => {
		if (isVertical) {
			setContainerHeight(`calc(${getHeight()}px - ${document.body.style.paddingBottom})`);
		}
	}, []);

    React.useEffect(() => {
        let timeoutId: NodeJS.Timeout | null = null;
        onResize();

        const resizeListener = () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                onResize();
            }, 150);
        };

        window.addEventListener("resize", resizeListener);

        return () => {
            window.removeEventListener("resize", resizeListener);
        }
    }, []);

	return (
		<Layout pageId={PageId.Index} resizeCallback={onResize}>
			<Seo description={t("seoDescription")} />
			<div className="landingContainer" style={{ height: containerHeight }}>
				<div className="landingBGImage" style={{ width: getBGImageWidth(isVertical, i18n.resolvedLanguage) }}>
					<StaticImage
						src="../images/bgImage.jpeg"
						quality={95}
						formats={["auto", "webp", "avif"]}
						alt="landingBG"
						placeholder="blurred"
						objectFit="cover"
						objectPosition="50% 50%"
						style={{
							width: "100%",
							height: "100%",
						}}
					/>
				</div>
				<div className="mainRightBlock">
					<div className="rureMainImage">
						<Rure fill={isVertical ? COLORS.white : undefined} />
					</div>
					<h1 className="mainHeading">
						{t("mainHeading")}
					</h1>
				</div>
			</div>
		</Layout>
	);
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index", "common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
